.detail-width[data-v-f6cb2b45] {
  position: relative;
  flex: 1;
  overflow: auto;
}
.detail-container[data-v-f6cb2b45] {
  position: relative;
  border-radius: 8px;
  margin-right: 8px;
  padding: 8px;
  padding-top: 0px;
  padding-left: 0px;
}
.detail-container[data-v-f6cb2b45]:hover {
  background: #f0f2f5;
}
.detail-container:hover .to_detail[data-v-f6cb2b45] {
  opacity: 1;
}
.detail-container .label[data-v-f6cb2b45] {
  margin-right: 8px;
  font-weight: bolder;
}
.detail-container .label .fullname[data-v-f6cb2b45] {
  vertical-align: bottom;
  line-height: 25px;
}
.detail-container .value[data-v-f6cb2b45] {
  flex: 1;
  white-space: pre-wrap;
  word-break: break-all;
}
