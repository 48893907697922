.update_record_container[data-v-6fe9bb1e] {
  height: 50vh;
  overflow: auto;
}
.update_record_container .update_record_item[data-v-6fe9bb1e] {
  margin-bottom: 8px;
}
.update_record_container .update_record_item .update_record_label[data-v-6fe9bb1e] {
  margin-right: 8px;
  font-weight: bolder;
}
